import React from 'react';
import styled from 'styled-components';
import CwayLogo, {AnimationType} from '../CwayLogo';

const Background = styled.div<{$fullscreen: boolean}>`
  position: ${({$fullscreen}) => ($fullscreen ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({theme}) => theme.loadingIndicator.zIndex};

  background-color: ${({theme}) => theme.loadingIndicator.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  fullscreen?: boolean,
  size?: number,
  hasBackground?: boolean,
}

/** If fullscreen == false (loading indicator is displayed over a separate element, not entire window),
 *  parent element should have "position" css property set to handle absolute-positioned LoadingIndicator.
 *  Otherwise, LoadingIndicator will be positioned relative to nearest parent with "position" css property set.
 */
const LoadingIndicator = ({ fullscreen = false, size, hasBackground = false }: Props) => {
  const rotatingLogo = <CwayLogo size={size || (fullscreen ? 60 : 40)} animation={AnimationType.ROTATE} />;

  return (hasBackground || fullscreen) ? (
    <Background $fullscreen={fullscreen}>
      {rotatingLogo}
    </Background>
  ) : rotatingLogo;
}

export default LoadingIndicator;
