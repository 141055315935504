import styled from "styled-components";
import React from "react";
import {gql, useQuery} from "@apollo/client";

const Badge = styled.span`
  background-color: #f0735a;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 0 6px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 15px;
`;

interface Props {
    organisationId: string
}

const ORGANISATION_QUERY = gql`
    query OrganisationQuery($id: UUID!) {
        organisation(id: $id) {
            id
            badgeCount {
                projects
                quickShare
            }
        }
    }
`;

const OrganisationBadgeCount = ({organisationId}: Props) => {
    const {loading, error, data} = useQuery(ORGANISATION_QUERY, {variables: {id: organisationId}})
    if (loading || error || data?.organisation?.badgeCount == null) return null;
    const org = data?.organisation;
    if (org == null) return null;
    const totalCount = org.badgeCount.projects + org.badgeCount.quickShare;
    if (totalCount === 0) return null;
    return <Badge>{totalCount}</Badge>
}

export default OrganisationBadgeCount;
