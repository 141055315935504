// Packages
import React from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { colors } from '@collabra/cway-frontend-common/constants';

const Overlay = styled.div`
  z-index: ${({theme}) => theme.modal.zIndex};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;
  //background: rgba(0, 0, 0, 0.8);
  max-height: 100%;
`;
const ModalRoot = styled.div`
  border-radius: 6px;
  overflow: hidden;   // make border-radius (corners of child elements are visible without it)
  width: 400px;
  max-width: 90%;
  max-height: 70%;
  background-color: #F8F8F8;
  box-shadow: 0 0 5px black;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  font-size: 1.1em;
  line-height: 1;
  padding: 10px;
  color: ${colors.white};
  background-color: #07445A;
`;
const Content = styled.div`
  padding: 10px;
  font-size: 12px;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;
const Footer = styled.div`
  padding: 5px 10px 10px;
  display: flex;
  justify-content: space-between;
`;
const FooterButtonWrapper = styled.div`
  // If the button is alone it will be aligned to right.
  // For multiple buttons this doesn't have any effect, and they are equally spaced.
  &:last-of-type {
    margin-left: auto;
  }
  // Minimal interval between multiple buttons
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`;

interface Props {
  customClasses?: { content?: string },
  shown?: boolean,
  title: string,
  content: React.ReactNode,   // allows multiple elements, strings, numbers, fragments, portals, ...
  buttons: JSX.Element[],
}

const Modal = ({ customClasses = {}, shown = true, title, content, buttons }: Props) => {
  if (!shown) {
    return null;
  }

  const contentCustomClasses = cn({ [customClasses.content ?? '']: customClasses && customClasses.content });

  return (
    <Overlay>
      <ModalRoot>
        <Header>{title}</Header>

        <Content className={contentCustomClasses}>{content}</Content>

        {(buttons.length > 0) && (
          <Footer>
            {buttons.map((btn) => <FooterButtonWrapper key={uuidv4()}>{btn}</FooterButtonWrapper>)}
          </Footer>
        )}
      </ModalRoot>
    </Overlay>
  );
};

export default Modal;
