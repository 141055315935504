import { Navigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import Api from '../Api';
import Logo from './Logo';
import {FormPanel} from './Form/StyledComponents';
import Error from './Error';
import styled from 'styled-components';

const Header = styled.h1`
  margin-bottom: 30px;
  font-size: x-large;
`;
const Text = styled.span`
  color: #5bb5fd;
`;

const ChangeEmail = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'Change email | Cway';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway change email page');

  // ------------------------------------------------------------------------------------------

  const { requestId } = useParams<{ requestId?: string }>();

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [working, setWorking] = useState(false);
  const [showSuccessText, setShowSuccessText] = useState(false);

  let timer: ReturnType<typeof setTimeout>;
  const displayError = (message: string) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
    setErrorMessage(message);
  };

  const onError = (message: string) => {
    console.log(message);
    displayError(message);
    setWorking(false);
    setTimeout(() => {
      setSuccess(true);
    }, 5000);
  };

  const onSuccess = () => {
    setWorking(false);
    setShowSuccessText(true);
    setTimeout(() => {
      setSuccess(true);
    }, 5000);
  };

  const verifyEmail = () => {
    setWorking(true);
    Api.verifyEmailForRequest(onSuccess, onError, requestId);
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  if (success) return <Navigate to="/login" />;

  return (
    <FormPanel $hasBackground>
      <Logo />
      {!showSuccessText && (
        <>
          <h4>Verifying email </h4>
          {working && <LoadingIndicator fullscreen />}
        </>
      )}

      {showSuccessText && (
        <div>
          <Header>Success!</Header>
          <Text>Your new email address is verified. You can now login using that email as your username.</Text>
        </div>
      )}

      {errorMessage && <Error errorMessage={errorMessage} />}
    </FormPanel>
  );
};

export default ChangeEmail;
