import React, {ElementType} from 'react';

interface Props {
  // String: local file, ElementType: FontAwesome icon element
  tag: string | ElementType,
  props?: any[],
}
/**
 * Special icon for using with styled-components
 * */
const DynamicIcon = ({tag, ...props}: Props) => React.createElement(tag, props);

export default DynamicIcon;
