import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { BackendAuthResponse, BackendAnonymousShare } from './types/backend';
import {OrganisationMembership} from '@collabra/cway-frontend-common/types/__generated__/graphql';

// Comparison to axios
export const apiRequest = async (
  url: string,
  method: string,
  bodyParams: { [key: string]: string | number },
) => {
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: bodyParams ? JSON.stringify(bodyParams) : undefined,
  });
  return response.json();
};

// Basic auth for the graphql server
const basicAuth = {
  auth: {
    username: 'EoYCm7LFks0aSI5RCMiNjCvOEehG5EEC',
    password: 'YaFAERl0fJX2Pfl7Qd6m',
  },
};

const headers = { 'X-Calling-App': 'CWAY_LOGIN' };
const TIMEOUT = 2000;

const LOGIN_TIMEOUT = 5000;

const restApi = axios.create({
  baseURL: '/rest/v1',
  timeout: TIMEOUT,
  headers,
});

const oathApi = axios.create({
  baseURL: '/oauth',
  timeout: LOGIN_TIMEOUT,
  headers,
});

const switchApi = axios.create({
  baseURL: '/switch',
  timeout: TIMEOUT,
  headers,
});

const intercomApi = axios.create({
  baseURL: '/rest/intercom',
  timeout: TIMEOUT,
  headers,
});

export default class Api {
  static generateNewPasswordRequest(
    username: string,
    onError: (errorMessage: string) => void,
    onDone: (response: AxiosResponse<BackendAuthResponse>) => void,
  ) {
    restApi.post('/public/changepasswordrequest', { email: username })
      .then((response) => (onDone && onDone(response)))
      .catch((error) => (onError && onError(error.message)));
  }

  static loginToServer(
    username: string,
    password: string,
    organisation_id: string | null,
    onError: (errorMessage: string) => void,
    onDone: (response: AxiosResponse<BackendAuthResponse>) => void,
    updateToken?: (accessToken: string, refreshToken: string) => void,
  ) {
    oathApi.post('/token',
      qs.stringify({ grant_type: 'password', username, password , organisation_id}),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        ...basicAuth,
      })
      .then((response) => {
        console.log('Login success. Status:', response.status);
        const data = response.data;
        if (data.redirectUrl != null) {
          window.location.href = data.redirectUrl;
        } else {
          if (updateToken) updateToken(data.access_token, data.refresh_token);
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          if (onDone) onDone(data);
        }
      })
      .catch((error) => {
        console.log('Got error', error);
        if (onError) onError(error.message);
      });
  }

  static tryToLoginWithoutPassword(username: string, organisation_id: string | null, onError: (errorMessage: string) => void) {
    oathApi.post('/token',
      qs.stringify({ grant_type: 'password', username, organisation_id }),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        ...basicAuth,
      })
      .then((response) => {
        console.log('Login success. Status:', response.status);
        const data = response.data;
        if (data.redirectUrl != null) {
          window.location.href = data.redirectUrl;
        } else {
          onError('Shouldn\'t be allowed to login');
        }
      })
      .catch((error) => {
        console.log('Couldn\'t login without password');
        if (onError) onError(error.message);
      });
  }

  static loginWithSSOToken(
    token: string,
    state: string | null,
    onError: (errorMessage: string) => void,
    onDone: (response: AxiosResponse<BackendAuthResponse>) => void,
    updateToken?: (accessToken: string, refreshToken: string) => void,
  ) {
    oathApi.post('/token',
      qs.stringify({ grant_type: 'azure', token, state }),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        ...basicAuth,
      })
      .then((response) => {
        console.log('Login success. Status:', response.status);
        const data = response.data;
        if (updateToken) updateToken(data.access_token, data.refresh_token);
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
        if (onDone) onDone(data);
      })
      .catch((error) => {
        console.log('Got error', error);
        if (onError) onError(error.message);
      });
  }

  static checkServerVersion(
    onDone: (response: AxiosResponse<BackendAuthResponse>) => void,
    onError: (errorMessage: string) => void,
  ) {
    restApi.get('/public/version')
      .then((response) => {
        if (onDone) onDone(response);
      })
      .catch((error) => {
        if (onError) onError(error);
      });
  }

  static selectOrganisation(
    org: OrganisationMembership,
    jwtRefreshToken: string,
    onDone: (response: AxiosResponse<BackendAuthResponse>) => void,
  ) {
    switchApi.post('/organisation',
      qs.stringify({ organisation: org.organisationId }),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        ...basicAuth,
      },
    )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.access_token) {
          const data = response.data;

          // Store cookies
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          if (onDone) onDone(data);
        }
      })
      .catch(() => {

      });
  }

  static setPasswordForRequest(
    onSuccess: () => void,
    onError: (errorMessage: string) => void,
    password: string,
    requestId: string = '',
  ) {
    restApi.post('/public/setpasswordforrequest',
      {
        requestId,
        password,
      })
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        if (onError && error.response && error.response.data) onError(error.response.data.message);
      });
  }

  static verifyEmailForRequest(
    onSuccess: () => void,
    onError: (errorMessage: string) => void,
    requestId: string = '',
  ) {
    restApi.post(`/public/verifyEmail/${requestId}`)
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        if (onError && error.response && error.response.data) onError(error.response.data.message);
      });
  }

  static getAnonymousDownload(
    onDone: (response: BackendAnonymousShare) => void,
    onError: (errorMessage: string) => void,
    requestId: string = '',
  ) {
    restApi.get(`/public/share/${requestId}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const data = response.data;
          if (onDone) onDone(data);
        }
      })
      .catch((error) => {
        if (onError && error?.response?.data) onError(error.response.data.message);
      });
  }

  static getIntercomAppId(onDone: (appId: string) => void) {
    intercomApi.get('/appid')
      .then((response) => {
        if (onDone && (response.status === 200) && response.data && response.data.appId) {
          onDone(response.data.appId);
        }
      })
      .catch(() => {
      });
  }
}
