import React from 'react';
import styled from 'styled-components';
import softwareAdviceBadge from '../../img/icons/software-advice-most_recommended-2024.png';

const Root = styled.div`
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
`;
const Link = styled.a<{$isCapterra?: boolean}>`
    // Capterra image has a protrusion to the left from the basic rectangle,
    // and width of protruding part is about 7% of the rectangle,
    // so align the left edge of the rectangle with left edge of root container and let the protrusion to stick out
    width: ${({$isCapterra}) => $isCapterra ? '107%' : '100%'};
    display: flex;
    justify-content: center;
`;
const Img = styled.img`
    width: 100%;
`;
const ImgWidened = styled.img`
    width: 90%;
`;

const GartnerLinks = () => (
  <Root>
    <Link href="https://www.softwareadvice.com/cms/cway-profile/reviews/" target="_blank" rel="noopener noreferrer">
      <ImgWidened src={softwareAdviceBadge} alt=""/>
    </Link>

    <Link $isCapterra href="https://www.capterra.com/p/10009310/Cway/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge" target="_blank" rel="noopener noreferrer">
      <Img src="https://assets.capterra.com/badge/124602db971c1edb9049d8fbf50edc99.svg?v=2288344&p=341059" alt=""/>
    </Link>

    <Link href="https://www.getapp.com/marketing-software/a/cway/reviews/" target="_blank" rel="noopener noreferrer">
      <Img src="https://www.getapp.com/ext/reviews_widget/v1/dark/cway-application" alt=""/>
    </Link>

    {/*<Link href="https://www.softwareadvice.com/cms/cway-profile/reviews/" target="_blank" rel="noopener noreferrer">*/}
    {/*  <Img src="https://badges.softwareadvice.com/reviews/bb07c0b7-f086-4fed-ac61-f07736b83025" alt=""/>*/}
    {/*</Link>*/}
  </Root>
);

export default GartnerLinks;
