import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  background-color: white;
  padding: 35px;
  font-size: 12px;
`;
const H1 = styled.h1`
  font-size: 16px;
  font-weight: bolder;
`;
const H2 = styled.h2`
  margin-top: 18px;
  font-size: 14px;
  font-weight: bold;
`;
const H3 = styled.h3`
  font-size: 12px;
  font-style: italic;
`;
const Table = styled.table`
  margin: 20px;
  width: 80%;
`;
const Td = styled.td`
  padding: 3px;
`;
const Li = styled.li`
  margin-bottom: 5px;
`;

const EulaText = () => (
  <Root>
    <H1>Cway Services End User License Agreement</H1>
    <p>Applicable from: 25 April 2018</p>
    <ol id="l1">
      <Li data-list-text="1.">
        <H2>Agreement</H2>
        <ol id="l2">
          <Li data-list-text="1.1">
            The following terms of service and end user license agreement (“EULA”) constitute an agreement
            between
            you, the “End User”, and Collabra AB (the “Supplier”). This EULA governs your use the Cway
            Services (as
            specified below).
          </Li>
          <Li data-list-text="1.2">
            The Cway Services may be accessed by End Users only in accordance with this Agreement and the
            agreement
            with the Customer. Any references to “Customer” shall understood as references to the End User
            or the
            person or entity which the End User is associated with, as an employee, a consultant or as the
            case may
            be.
          </Li>
        </ol>
      </Li>
      <Li data-list-text="2.">
        <H2>Cway Services and System Requirements</H2>
        <ol id="l3">
          <Li data-list-text="2.1">
            The Cway Services are online services accessible through an Internet browser. Internet
            access is required to utilize the Cway Services.
          </Li>
          <Li data-list-text="2.2">
            Monitor resolution must be min. 1024x768. Using a smaller screen may disable the artwork
            preview function, making it necessary to download the artwork PDF in order to view it.
          </Li>
          <Li data-list-text="2.3">
            <p>The following browser versions are supported.</p>
            <Table>
              <thead>
                <tr>
                  <Td as="th">Browser</Td>
                  <Td as="th">Oldest version</Td>
                  <Td as="th">Recommended version</Td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>Google Chrome</Td>
                  <Td>2 gen back from current</Td>
                  <Td>current</Td>
                </tr>
                <tr>
                  <Td>Mozilla Firefox</Td>
                  <Td>2 gen back from current</Td>
                  <Td>current</Td>
                </tr>
                <tr>
                  <Td>Microsoft Edge</Td>
                  <Td>2 gen back from current</Td>
                  <Td>current</Td>
                </tr>
              </tbody>
            </Table>
          </Li>
          <Li data-list-text="2.4">
            The minimum browser window height should be 600 px. On screens with resolution 1024x768, the
            browser window should be maximized and set to Full Screen. Using a smaller browser option will
            disable the artwork preview function, making it necessary to download the artwork PDF in order
            to view it.
          </Li>
          <Li data-list-text="2.5">
            <p>The Cway Services currently consists of the following modules:</p>
            <ol id="l4">
              <Li data-list-text="a)">
                Quickshare, a module used to transfer, store and share data files;
              </Li>
              <Li data-list-text="b)">
                Project Manager, a module used for development of, and project collaboration on,
                graphic data files for packaging design and packaging graphics; and
              </Li>
              <Li data-list-text="c)">
                Media Center, a module for structural storage of Customer graphic data files.
              </Li>
            </ol>
          </Li>
          <Li data-list-text="2.6">
            <H3>Facilities and Data Processing</H3>
            <ol id="l5">
              <Li data-list-text="2.6.1">
                The Supplier will use, at a minimum, industry standard technical and
                organizational security measures to transfer, store, and process Customer
                Data. These measures are designed to protect the integrity of Customer Data
                and guard against the unauthorized or unlawful access to, use, and
                processing of Customer Data.
              </Li>
              <Li data-list-text="2.6.2">
                By using the Cway Services, the End User approves on behalf of the Customer
                that the Supplier may transfer, store, and process Customer Data in
                locations other than Customer&#39;s country. &quot;Customer Data&quot; means
                Stored Data and Account Data. &quot;Stored Data&quot;
                means the files and structured data submitted to the Services by the
                Customer, the Supplier or End Users. &quot;Account Data&quot; means the
                account and contact information submitted to the Services by Customer or End
                Users.
              </Li>
              <Li data-list-text="2.6.3">
                As Part of the Cway Services, Supplier may as processor of personal data
                process the End Users personal data (name and e-mail address) in order to
                fulfil the Supplier’s contractual obligations towards the Customer, being
                the personal data controller. Taking into account the state of the art, the
                costs of implementation and the nature, scope, context and purposes of
                processing as well as the risk of varying likelihood and severity for the
                rights and freedoms of natural persons, appropriate technical and
                organisational measures has been implemented to ensure a level of security
                appropriate to the risk.
              </Li>
              <Li data-list-text="2.6.4">
                The End User personal data have been provided either by the End User or the
                Customer. The End User personal data may be disclosed to the following
                categories of recipients: [other users in the system for completing the process
                (e.g. members in artwork projects), administrators, developers of the system
                when needed]. The envisaged period for which the End User personal data may be
                processed is during the term of the Cway Services agreement between the Customer
                and the Supplier, and in the following situations, for a period of time of ten
                years and two months from the date of approval of work if such approved work has
                been approved by the End User. If personal data are transferred to a third
                country or to an international organisation, appropriate safeguards pursuant to
                Article 46 of REGULATION (EU) 2016/679 OF THE EUROPEAN
                PARLIAMENT AND OF THE COUNCIL of 27 April 2016 on the protection of natural
                persons with regard to the processing of personal data and on the free movement
                of such data, relating to the transfer will be applied apply.
              </Li>
              <Li data-list-text="2.6.5">
                The End User may request from the controller (the Customer) rectification or
                erasure of personal data or restriction of processing of personal data
                concerning the data subject or to object to such processing. The End User must
                contact the Customer to make such requests or to obtain further information as
                regards safeguarding of personal data. If the End User has no contact
                information regarding the Customer, the End User may contact the Supplier on
                <a
                  href="mailto:personaldata@collabra.se"
                  target="_blank"
                  rel="noreferrer"
                >personaldata@collabra.se
                </a> to
                obtain such contact information. The End User has also the right to lodge a
                complaint with
                the supervisory authority Datainspektionen in Sweden
                (datainspektionen@datainspektionen.se).
              </Li>
            </ol>
          </Li>
          <Li data-list-text="2.7">
            <H3>Modifications of the Cway Services</H3>
            <p>
              The Supplier may update the Cway Services from time to time. If such changes to the Cway
              Services materially reduce their functionality, the Supplier will inform the Customer
              thereof via email. By continuing to access or use the Cway Services after changes become
              effective, the End User approves such changes on behalf of the Customer.
            </p>
          </Li>
        </ol>
      </Li>
      <Li data-list-text="3.">
        <H2>End User obligations</H2>
        <ol id="l8">
          <Li data-list-text="3.1">
            End Users may only use the Services in compliance with the Acceptable Use Policy, attached
            hereto.
          </Li>
          <Li data-list-text="3.2">
            The End User will comply with laws and regulations applicable to End User’s use of the Services,
            if any.
          </Li>
          <Li data-list-text="3.3">
            The Supplier’s responsibilities do not extend to the internal management or administration of
            the Cway Services.
          </Li>
          <Li data-list-text="3.4">
            The End User is responsible for (i) maintaining the confidentiality of passwords and accounts;
            (ii)
            using the Cway Services only in compliance with this Agreement and the Cway Services agreement
            between
            the Customer and the Supplier.
          </Li>
          <Li data-list-text="3.5">
            The End User shall not use the Services unauthorized.
          </Li>
          <Li data-list-text="3.6">
            The End User will not (i) sell, resell, or lease the Services; or (ii) reverse engineer the
            Services, nor attempt nor assist anyone else to do so.
          </Li>
        </ol>
      </Li>
      <Li data-list-text="4.">
        <H2>Suspension</H2>
        <ol id="l9">
          <Li data-list-text="4.1">
            If an End User (i) violates this Agreement or (ii) uses the Cway Services in a manner that the
            Supplier reasonably believes will cause the Supplier liability, and there is an immediate need
            to suspend or terminate such End User account, then the Supplier may do so.
          </Li>
          <Li data-list-text="4.2">
            Notwithstanding anything in this Agreement, if there is a Security Emergency then the Supplier
            may automatically suspend use of the Cway Services. The Supplier will make commercially
            reasonable efforts to narrowly tailor the suspension as needed to prevent or terminate the
            Security Emergency. &quot;Security Emergency&quot; means: (i) use of the Services that do or
            could disrupt the Cway Services, other end user’s use of the Cway Services, or the
            infrastructure used to provide the Cway Services and (ii) unauthorized third-party access to the
            Services.
          </Li>
        </ol>
      </Li>
      <Li data-list-text="5.">
        <H2>Fees</H2>
        <p>
          If not the applicable fees have been duly paid, then the Supplier may immediately suspend or
          terminate the End User account associated to the non- paying Customer.
        </p>
      </Li>
      <Li data-list-text="6.">
        <H2>Service Level</H2>
        <p>
          The Supplier will use commercially reasonable efforts to make the Cway Services available to the
          Customer. A 100 per cent uptime of the Cway Services is not possible to achieve. Any and all
          remedies for downtime of the Cway Services are set forth in the Cway Servicews agreement between the
          Customer and the Supplier.
        </p>
      </Li>
      <Li data-list-text="7.">
        <H2>Improvement of Services</H2>
        <ol id="l10">
          <Li data-list-text="7.1">
            The Supplier may collect information from the End User’s use of the Cway Services, such as but
            not
            limited to how the Cway Services are used, with whom End Users interact, device information, log
            information and location information, also by use of cookies and anonymous identifiers.
          </Li>
          <Li data-list-text="7.2">
            The collected information may be used, free of charge and without limitation in time, by the
            Supplier to
            provide, maintain, protect and improve the Cway Services, and to develop new services.
          </Li>
        </ol>
      </Li>
      <Li data-list-text="8.">
        <H2>Consequences of termination</H2>
        <p>
          When the Cway Services agreement has terminated, the End Users will no longer have access to the
          Cway Services, except for downloading Stored Data. Six (6) months after termination, the Supplier
          will have no obligation to store or retain Customer Data, including Stored Data, and may at its own
          discretion erase such data.
        </p>
      </Li>
      <Li data-list-text="9.">
        <H2>Indemnification</H2>
        <p>
          The End User will indemnify, defend, and hold harmless the Supplier from and against all
          liabilities, damages, and costs (including settlement costs and reasonable attorneys&#39; fees)
          arising out of any claim by a third party against the Supplier and its affiliates regarding the End
          Users&#39; use of the Services in violation
          of this Agreement.
        </p>
      </Li>
      <Li data-list-text="10.">
        <H2>Limitation of Liability</H2>
        <ol id="l11">
          <Li data-list-text="10.1">
            <p>
              The Supplier’s responsibilities for damage due to errors or delay are limited in accordance
              with the following. The Supplier will not be liable for indirect
            </p>
            <p>
              damages, including but not limited to loss of profit, loss of data, and costs related to
              production, unless caused by gross negligent or intent.
            </p>
          </Li>
          <Li data-list-text="10.2">
            The maximum aggregate liability for any loss or damage, whether direct or indirect, is set forth
            in the Cway Services agreement between the Customer and the Supplier.
          </Li>
          <Li data-list-text="10.3">
            Under no circumstances will the Supplier be liable for any loss or damage relating to
            deliverables that the End User has approved.
          </Li>
          <Li data-list-text="10.4">
            The suffering party will not be entitled to compensation unless claims are made in writing to
            the Supplier within three (3) months from the day the suffering party learns about or should
            reasonably have learned about the alleged damaging circumstances.
          </Li>
        </ol>
      </Li>
      <Li data-list-text="11.">
        <H2>Modification of terms</H2>
      </Li>
    </ol>
    <p>
      The Supplier may revise the terms for the Cway Services from time to time and the most current version of
      the terms will always be available at the Cway Services website. If a revision, in the Supplier’s sole
      discretion, is material, the Supplier will notify the Customer in writing by email. The End User approves on
      behalf of the Customer such revisions by continuing to access or use the Cway Services after revisions
      become effective. If the Customer does not agree to the revised terms, the Customer may terminate the
      Services within 30 days of receiving notice of the change.
    </p>
    <p>* * * *</p>
    <H1> Cway Services Acceptable Use Policy</H1>
    <H2>Acceptable Use Policy</H2>
    <p>
      The End User will not misuse the Cway Services. For example but not limited to, the End User must not, and
      must not attempt to do the following:
    </p>
    <ol id="l12">
      <Li data-list-text="a)">probe, scan, or test the vulnerability of any system or network;
      </Li>
      <Li data-list-text="b)">
        breach or otherwise circumvent any security or authentication measures;
      </Li>
      <Li data-list-text="c)">
        access, tamper with, or use non-public areas or parts of the Cway Services, or
        shared areas of the Cway Services the End
        User have not been invited to;
      </Li>
      <Li data-list-text="d)">
        interfere with or disrupt any user, host, or network, for example by sending a virus, overloading,
        flooding, spamming, or mail-bombing any part of the Cway Services;
      </Li>
      <Li data-list-text="e)">
        access or search the Cway Services by any means other than the Supplier’s publicly supported interfaces;
      </Li>
      <Li data-list-text="f)">
        send unsolicited communications, promotions or advertisements, or spam;
      </Li>
      <Li data-list-text="g)">
        send altered, deceptive or false source-identifying information,
        including &quot;spoofing&quot; or &quot;phishing&quot;;
      </Li>
      <Li data-list-text="h)">
        promote or advertise products or services;
      </Li>
      <Li data-list-text="i)">
        abuse the Cway Services in a manner that circumvents their storage space limits;
      </Li>
      <Li data-list-text="j)">
        sell the Cway Services or Cway Services accounts via unauthorized channels, or provide access to the
        Cway Services to unauthorized users in other ways than as provided for in the Cway Services;
      </Li>
      <Li data-list-text="k)">
        use automated or other means to create accounts in bulk or to access the Services other than by using
        the Supplier’s official interface and/or APIs;
      </Li>
      <Li data-list-text="l)">
        publish or share materials that are unlawfully pornographic or indecent, or that advocate bigotry,
        religious, racial or ethnic hatred;
      </Li>
      <Li data-list-text="m)">
        violate law in any way including storing, publishing or sharing material that is fraudulent, defamatory,
        misleading, or that violates the privacy or infringes the rights of others.
      </Li>
    </ol>
  </Root>
);

export default EulaText;
