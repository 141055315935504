import Confetti from 'react-confetti';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { recaptchaSiteKey } from '@collabra/cway-frontend-common/constants';
import { loadScriptByURL, isValidEmail } from '@collabra/cway-frontend-common/utils';
import { CheckIcon } from '../Icons';
import LoadingIndicator from '../LoadingIndicator';
import {FormPanel, TextInput} from '../Form/StyledComponents';
import Logo from '../Logo';
import Socials from '../Socials';
import Error from '../Error';
import styled from 'styled-components';
import {Button} from '../ButtonStyles';
import Checkbox from '../Form/Checkbox';

const animationDuration = 1;  // seconds

const FadableWrapper = styled.div<{$invisible: boolean}>`
  opacity: ${({ $invisible }) => $invisible ? 0 : 1};
  transition: opacity ${animationDuration}s;
  transition-delay: ${animationDuration}s;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
const FadableWrapperOverlay = styled.div<{$invisible: boolean}>`
  z-index: 1;
  overflow: hidden;
  height: ${({ $invisible }) => $invisible ? 0 : 'auto'};
  opacity: ${({ $invisible }) => $invisible ? 0 : 1};
  transition: opacity ${animationDuration}s;
  transition-delay: ${animationDuration}s;
  
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;
const SuccessSignBg = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #A6A06F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
`;
const SuccessSignIcon = styled(CheckIcon)`
  color: white;
  width: 70%;
  height: 70%;
`;
const SuccessTextBig = styled.p`
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
`;
const SuccessTextSmall = styled.p`
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding: 0 50px;
`;
const CheckboxStyled = styled(Checkbox)`
  align-self: flex-start;
  font-size: small;
`;
const Link = styled.a`
  color: white;
`;

const removeScript = (id: string, callback: () => void) => {
  const scriptElement = document.getElementById(id);
  if (scriptElement) {
    const parent = scriptElement.parentNode;
    parent?.removeChild(scriptElement);
    if (callback) callback();
  }
};

interface Props {
  error: string | null,
  loading: boolean,
}

const Onboard = ({ error, loading }: Props) => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'Register | Cway';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway register page');

  // ---------- Input fields --------------------

  const [textInputs, setTextInputs] = useState<{ [key: string]: string }>({ firstName: '', lastName: '', email: '', organisation: '' });
  const changeTextInput = (name: string, value: string) => setTextInputs((prevInputs) => ({ ...prevInputs, [name]: value }));

  const getInputPlaceholder = (inputName: string) => {
    switch (inputName) {
      case 'firstName': return 'First Name';
      case 'lastName': return 'Last Name';
      case 'email': return 'Email Address';
      case 'organisation': return 'Organisation';
      default: return '';
    }
  };
  const getInputType = (inputName: string) => {
    if (inputName === 'email') return 'email';
    return 'text';
  };

  const [checkInputs, setCheckInputs] = useState<{ [key: string]: boolean }>({ consentToNewsletter: false, acceptedTerms: false });
  const changeCheckInput = (name: string, value: boolean) => setCheckInputs((prevCheckInputs) => ({ ...prevCheckInputs, [name]: value }));

  const formValid = textInputs.firstName.length > 0 && textInputs.lastName.length > 0 && isValidEmail(textInputs.email)
    && textInputs.organisation.length > 0 && checkInputs.acceptedTerms;

  // Submit form on press Enter in any field
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (formValid) {
      const e = event.nativeEvent;
      const enterPressed = e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter';
      if (enterPressed && formValid) console.log('Submit form by Enter');
    }
  };

  // Focus the 1st input on load the page
  const firstInput = useRef<HTMLInputElement>(null);
  useEffect(() => firstInput.current?.focus(), []);

  // ---------- Google recaptcha --------------------

  useEffect(() => {
    // On component mount: load recaptcha script and display badge (if it was hidden earlier) for production environment
    if (process.env.NODE_ENV === 'production') {
      loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`,
        () => console.log('reCAPTCHA script loaded'),
      );
      const widgetNode = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement | null;
      if (widgetNode) {
        widgetNode.style.visibility = 'visible';
        console.log('reCAPTCHA widget made visible');
      }
    }

    // On component unmount: remove recaptcha script and hide badge for production environment
    return () => {
      if (process.env.NODE_ENV === 'production') {
        removeScript('recaptcha-key', () => console.log('reCAPTCHA script removed'));
        const widgetNode = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement | null;
        if (widgetNode) {
          widgetNode.style.visibility = 'hidden';
          console.log('reCAPTCHA widget hidden');
        }
      }
    };
  }, []);

  // ---------- Flag to redirect to login screen --------------------

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  // ---------- Onboard register request --------------------

  const basicAuthToken = 'RW9ZQ203TEZrczBhU0k1UkNNaU5qQ3ZPRWVoRzVFRUM6WWFGQUVSbDBmSlgyUGZsN1FkNm0=';
  const fetchOptionsBasic: RequestInit = {
    cache: 'no-cache',
    // credentials: 'same-origin',
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${basicAuthToken}`,
      'content-type': 'application/json',
      'X-Calling-App': 'CWAY_LOGIN',
    },
    method: 'POST',
    mode: 'cors',
  };

  const [success, setSuccess] = useState(false);

  const signup = async () => {
    console.group('Onboard.signup()');
    console.log('stringified body: ', JSON.stringify({ ...textInputs, consentToNewsletter: checkInputs.consentToNewsletter }));
    const fetchResponse = await fetch(
      '/rest/v1/public/register',
      {
        ...fetchOptionsBasic,
        body: JSON.stringify({ ...textInputs, consentToNewsletter: checkInputs.consentToNewsletter }),
      },
    );
    console.log('fetchResponse: ', fetchResponse);
    if (fetchResponse.ok) {
      setSuccess(true);
      // Redirect to login screen in 30 seconds
      setTimeout(() => setRedirectToLogin(true), 30000);
    }
    console.groupEnd();
    return fetchResponse.json();
  };

  const submitForm = (e: React.MouseEvent) => {
    e.preventDefault();
    if (process.env.NODE_ENV === 'production') {
      // @ts-ignore
      window.grecaptcha.ready(() => {
        // @ts-ignore
        window.grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(() => {
          signup().then(() => setSuccess(true));
        });
      });
    } else {
      signup().then(() => setSuccess(true));
    }
  };

  // ---------- Redirect to login screen after a delay --------------------

  if (redirectToLogin) return <Navigate to="/login" />;

  // ------------------------------------------------------------------------------------------

  return (
    <>
      <FormPanel $hasBackground>
        <FadableWrapper $invisible={success}>
          <Logo />

          {Object.keys(textInputs).map((inputName, index) => (
            <TextInput
              key={inputName}
              ref={index === 0 ? firstInput : null}
              type={getInputType(inputName)}
              placeholder={getInputPlaceholder(inputName)}
              onChange={(e) => changeTextInput(inputName, inputName === 'email' ? e.target.value.toLowerCase() : e.target.value)}
              onKeyDown={(e) => handleKeyPress(e)}
              value={textInputs[inputName]}
            />
          ))}

          <CheckboxStyled
            label="Yes! Email me about all the juicy updates"
            checked={checkInputs.consentToNewsletter}
            onChange={(e) => changeCheckInput('consentToNewsletter', e.target.checked)}
          />
          <CheckboxStyled
            label={<span>I accept the <Link href="/signon/terms" target="_blank" rel="noopener noreferrer">Terms of Use</Link> and Privacy Policy</span>}
            checked={checkInputs.acceptedTerms}
            onChange={(e) => changeCheckInput('acceptedTerms', e.target.checked)}
          />

          <Button disabled={!formValid} onClick={submitForm}>
            SIGN UP
          </Button>
        </FadableWrapper>

        <FadableWrapperOverlay $invisible={!success}>
          <SuccessSignBg>
            <SuccessSignIcon/>
          </SuccessSignBg>
          <SuccessTextBig>Great!</SuccessTextBig>
          <SuccessTextSmall>Now, check your email to verify your account</SuccessTextSmall>
        </FadableWrapperOverlay>

        {error && <Error errorMessage={error} />}
        {loading && <LoadingIndicator fullscreen />}
      </FormPanel>

      <Socials />

      {success && <Confetti height={window.innerHeight} width={window.innerWidth} />}
    </>
  );
};

export default Onboard;
