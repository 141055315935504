import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  color: white;
  padding: 15px 15px 12px;
  border-radius: 5px;
  border: 1px solid red;
`;

interface Props {
  errorMessage: string,
}

const Error = ({ errorMessage }: Props) => (
  <Root>
    <div>{errorMessage}</div>
  </Root>
);

export default Error;
