import React, { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import {Button} from './ButtonStyles';
import { Navigate, useParams } from 'react-router-dom';
import {FormPanel, TextInput} from './Form/StyledComponents';
import Socials from './Socials';
import Logo from './Logo';
import Api from '../Api';
import Error from './Error';
import PasswordStrengthBar from 'react-password-strength-bar';
import { MINIMUM_PASSWORD_LENGTH } from '../constants';
import styled from 'styled-components';

const PwdStrBar = styled(PasswordStrengthBar)`
  align-self: stretch;
  & p {
    color: white !important;
  }
`;
const SuccessHeader = styled.h2`
  margin-bottom: 30px;
  font-size: x-large;
`;
const Text = styled.p`
  color: #5bb5fd;
  text-align: center;
`;

const SetPassword = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'Set password | Cway';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway set password page');

  // ---------- Flag to redirect to login screen --------------------

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  // ------------------------------------------------------------------------------------------

  const { requestId } = useParams<{ requestId?: string }>();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const formValid = password.length >= MINIMUM_PASSWORD_LENGTH && password === repeatPassword;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [requestRunning, setRequestRunning] = useState(false);
  const [showSuccessText, setShowSuccessText] = useState(false);

  let timer: ReturnType<typeof setTimeout>;
  const displayError = (message: string) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      setErrorMessage(null);
    }, 30000);
    setErrorMessage(message);
  };

  const onError = (message: string) => {
    console.log(message);
    displayError(message);
    setRequestRunning(false);
  };

  const onSuccess = () => {
    setRequestRunning(false);
    setShowSuccessText(true);
    // Redirect to login screen in 30 seconds
    setTimeout(() => setRedirectToLogin(true), 30000);
  };

  const sendPassword = () => {
    setRequestRunning(true);
    Api.setPasswordForRequest(onSuccess, onError, password, requestId);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const e = event.nativeEvent;
    if (formValid && (e.charCode === 13 || e.code === 'Enter')) {
      sendPassword();
    }
  };

  // ---------- Redirect to login screen after a delay --------------------

  if (redirectToLogin) return <Navigate to="/login" />;

  // ------------------------------------------------------------------------------------------

  return (
    <>
      <FormPanel $hasBackground>
        <Logo />

        {!showSuccessText && (
          <>
            <h4>Set password </h4>

            <TextInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e)}
            />

            <PwdStrBar password={password} minLength={MINIMUM_PASSWORD_LENGTH} />

            <TextInput
              type="password"
              placeholder="Repeat password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e)}
            />

            {!requestRunning && (
              <Button disabled={!formValid} onClick={() => sendPassword()}>
                Set password
              </Button>
            )}
          </>
        )}

        {showSuccessText && (
          <>
            <SuccessHeader>Success!</SuccessHeader>
            <Text>Your new password is set.<br />You can now login.</Text>
            <Button onClick={() => setRedirectToLogin(true)}>
              GO TO LOGIN
            </Button>
          </>
        )}

        {requestRunning && <LoadingIndicator fullscreen />}

        {errorMessage && <Error errorMessage={errorMessage} />}
      </FormPanel>

      <Socials />
    </>
  );
};

export default SetPassword;
