import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { endpoints } from '@collabra/cway-frontend-common/constants';
import possibleTypes from './possibleTypes';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const cache = new InMemoryCache({ possibleTypes });

// Default HTTP link is replaced with createUploadLink which allows to upload files
const httpLink = createUploadLink({ uri: endpoints.graphql });

// Add NextGen app identification to every request "X-Calling-App" header (to distinguish from old Cway app)
const idHeaderLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: Record<string, any>) => ({
    headers: {
      ...headers,
      'X-Calling-App': process.env.REACT_APP_CWAY_APPLICATION,
    },
  }));
  return forward(operation);
});

// Handle GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, 'color: orange');
    });
  }

  if (networkError) {
    console.log(`%c [Network error]: ${networkError}`, 'color: orange');
  }
});

const wsUri = `${(window.location.protocol === 'https:') ? 'wss:' : 'ws:'}//${window.location.host}`;
const wsLink = new WebSocketLink({ uri: `${wsUri}/subscriptions` });

const wsOrHttpLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const link = ApolloLink.from([errorLink, idHeaderLink, wsOrHttpLink]);

// Initialize Apollo client
const client = new ApolloClient({ link, cache });

export default client;
