import React from 'react';
import LoadingIndicator from './LoadingIndicator';
import {Button} from './ButtonStyles';
import {FormPanel} from './Form/StyledComponents';
import Logo from './Logo';
import Error from './Error';
import styled from 'styled-components';

const Text = styled.div`
  padding: 20px 0;
  text-align: center;
  color: white;
`;

interface Props {
  error: string | null,
  loading: boolean,
  goToLogin: () => void,
}

const PasswordRequested = ({ error, loading, goToLogin }: Props) => (
  <FormPanel $hasBackground>
    <Logo />
    <Text>Success! Follow link in email</Text>

    <Button onClick={goToLogin} $secondary>
      Back to login page
    </Button>

    {error && <Error errorMessage={error} />}
    {loading && <LoadingIndicator fullscreen />}
  </FormPanel>
);

export default PasswordRequested;
