import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
   *, *:before, *:after {
     /* https://css-tricks.com/international-box-sizing-awareness-day/ */
     box-sizing: border-box;
     margin: 0;
     border: 0;
     padding: 0;
     outline: 0;
   }

   *::-webkit-scrollbar-track {
       background-color: transparent;
       cursor: default;
   }
   *::-webkit-scrollbar {
       width: 10px;
       height: 10px;
       background-color: transparent;
       cursor: default;
   }
   *::-webkit-scrollbar-thumb {
       border-radius: 5px;
       background-color: #55555577;
       cursor: default;
   }

   html, body {
     overflow: hidden;
     height: 100%;
   }

   body {
     background-color: black;
     margin: 10px;
     padding: 0;
     font-family: 'Poppins', 'Arial', sans-serif;
     //font-size: 12px;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
   }

   #root {
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
   }

   code {
     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
   }

   ::selection {
     color: white;
     background-color: #328FFE;
   }
`;

export default GlobalStyle;
