import {LoginInfo} from '@collabra/cway-frontend-common/types/__generated__/graphql';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {urlQueryVar} from "../graphql/ReactiveVars";

interface Props {
  queryLoading: boolean,
  queryError?: ApolloError,
  loginInfo: LoginInfo,
}

const RedirectToCwayAppIfLoggedIn = ({queryLoading, queryError, loginInfo }: Props) => {
  const urlQuery = useReactiveVar(urlQueryVar);

  if (!queryLoading && !queryError && loginInfo && loginInfo.currentOrganisation && loginInfo.user.acceptedTerms && !loginInfo.shouldProvideName) {
    console.log(`URL: ${window.location.href}, redirect to CWAY app`);
    if (urlQuery) {
      window.location.href = `${process.env.REACT_APP_CWAY_HOST}${loginInfo.settings.startUrl}?${urlQuery}`;
    } else {
      window.location.href = `${process.env.REACT_APP_CWAY_HOST}${loginInfo?.settings?.startUrl}?${new Date().getTime()}`;
    }
  } else {
    console.log(`URL: ${window.location.href}, redirect to CWAY app SKIPPED`);
  }
  return null;
};

export default RedirectToCwayAppIfLoggedIn;
