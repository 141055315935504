import {Theme} from './Theme';
import zIndexLayers from './zIndexLayers';

const ModernTheme: Theme = {
    name: 'Modern',

    colors: {
        standardTextColor: '#000000',
    },

    modal: {
        zIndex: zIndexLayers.modalWindow,
    },

    notifications: {
        zIndex: zIndexLayers.notifications,
    },

    loadingIndicator: {
        zIndex: zIndexLayers.fullscreenLoadingIndicator,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
};

export default ModernTheme;
