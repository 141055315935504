import React from 'react';
import { LinkedInIcon, InstagramIcon, YoutubeIcon } from './Icons';
import styled from 'styled-components';
import DynamicIcon from './DynamicIcon';

const Root = styled.div`
  z-index: 1;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;
const Link = styled.a`
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  display: inline-flex;
  background-color: #93A1A2;
  border-radius: 50%;
  padding: 7px;
`;
const Icon = styled(DynamicIcon)`
  width: 25px;
  height: 25px;
  color: white;
`;
const Text = styled.div`
  margin-top: 10px;
  font-family: 'Londrina Solid', cursive;
  font-size: 22px;
`;

const Socials = () => (
  <Root>
    <div>
      {[
        { iconTag: YoutubeIcon, href: 'https://www.youtube.com/channel/UC5UC2Fdmq1ufVBN7JJnj8Zw/featured' },
        { iconTag: LinkedInIcon, href: 'https://www.linkedin.com/showcase/cwaysoftware/' },
        { iconTag: InstagramIcon, href: 'https://www.instagram.com/cwaysoftware/' },
      ].map(({ iconTag, href }) => (
        <Link key={href} href={href} target="_blank" rel="noreferrer">
          <Icon tag={iconTag} />
        </Link>
      ))}
    </div>

    <Text>Let&prime;s get social!</Text>
  </Root>
);

export default Socials;
