import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg<{$size: number, $color?: string}>`
  width: ${({$size}) => $size}px;
  height: ${({$size}) => $size}px;
  fill: ${({theme,$color}) => $color ?? theme.colors.standardTextColor};
  enable-background: new 0 0 100 100;
`;

export enum AnimationType {ROTATE, SHAKE_ON_MOUNT}

interface Props {
  size?: number,
  color?: string,
  animation?: AnimationType | null,
}

const CwayLogo = ({ size = 50, color, animation = null }: Props) => {
  let elementsAnimationType;
  switch (animation) {
    case AnimationType.ROTATE:
      elementsAnimationType = 'rotate';
      break;
    case AnimationType.SHAKE_ON_MOUNT:
      elementsAnimationType = 'none';
      break;
    default:
      elementsAnimationType = 'none';
      break;
  }

  return (
    <Icon $size={size} $color={color} version="1.1" id="New_logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve">
      <path d="M88.7,71.9C81.1,85.4,66.6,94.5,50,94.5c-24.5,0-44.5-20-44.5-44.5S25.5,5.5,50,5.5c16.5,0,31,9.1,38.7,22.5 l4.3-2.5C84.5,10.6,68.4,0.5,50,0.5C22.7,0.5,0.5,22.7,0.5,50S22.7,99.5,50,99.5c18.4,0,34.6-10.2,43.1-25.2L88.7,71.9z">
        <animateTransform
          attributeName="transform"
          calcMode="paced"
          dur="1.5s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
          type={elementsAnimationType}
        />
      </path>
      <path d="M78.7,66.2C73,76.2,62.3,83,50,83c-18.2,0-33-14.8-33-33s14.8-33,33-33c12.3,0,23,6.7,28.7,16.7l4.3-2.5 C76.5,19.8,64.1,12,50,12c-21,0-38,17-38,38s17,38,38,38c14.2,0,26.5-7.8,33.1-19.3L78.7,66.2z">
        <animateTransform
          attributeName="transform"
          calcMode="paced"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
          type={elementsAnimationType}
        />
      </path>
      <path d="M69.1,60.8C65.4,67.5,58.2,72,50,72c-12.1,0-22-9.9-22-22s9.9-22,22-22c8.2,0,15.3,4.5,19.1,11.1l4.3-2.5 C68.8,28.5,60,23,50,23c-14.9,0-27,12.1-27,27s12.1,27,27,27c10.1,0,18.9-5.5,23.5-13.7L69.1,60.8z">
        <animateTransform
          attributeName="transform"
          calcMode="paced"
          dur="0.67s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
          type={elementsAnimationType}
        />
      </path>
    </Icon>
  );
};

export default CwayLogo;
