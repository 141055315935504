import React, { useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import LoadingIndicator from './LoadingIndicator';
import {Button} from './ButtonStyles';
import {FormPanel, TextInput} from './Form/StyledComponents';
import {LoginInfo} from '@collabra/cway-frontend-common/types/__generated__/graphql';
import styled from 'styled-components';

const SET_NAME = gql`
    mutation ($firstName: String, $lastName: String) {
        setOwnUserRealName(firstName: $firstName, lastName: $lastName){
            id
            username
            firstName
            lastName
            email
            acceptedTerms
            name
        }
    }
`;
const LOGIN_INFO = gql`
    query LoginInfoNotLoggedIn {
        loginInfo {
            user {
                id
                username
                acceptedTerms
                firstName
                lastName
            }
            settings{
                startUrl
            }
            organisationMemberships{
                organisationId
                name
            }
            currentOrganisation{
                id
            }
            shouldProvideName
        }
    }
`;

interface Props {
  loginInfo: LoginInfo,
}

const UserDataInput = ({ loginInfo }: Props) => {
  console.group('UserDataInput()');
  console.log('loginInfo?.shouldProvideName: ', loginInfo?.shouldProvideName);

  // ---------- Set browser tab title and meta description --------------------

  document.title = 'User data input | Cway';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway user data input page');

  // ------------------------------------------------------------------------------------------

  const [firstNameLocal, setFirstNameLocal] = useState(loginInfo.user.firstName || '');
  const [lastNameLocal, setLastNameLocal] = useState(loginInfo.user.lastName || '');

  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);

  const [setName, { loading: setNameInProcess }] = useMutation(SET_NAME,
    {
      variables: { firstName: firstNameLocal, lastName: lastNameLocal },
      refetchQueries: [{ query: LOGIN_INFO }],
    },
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const e = event.nativeEvent;
    if (e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter') {
      setName();
    }
  };

  console.groupEnd();

  if (setNameInProcess) {
    return <LoadingIndicator fullscreen />;
  }

  return (
    <FormPanel $hasBackground>
      <p>
        Please provide us with your personal information to make it easier for your colleagues and partners to find you.
      </p>

      <TextInput
        ref={firstNameInput}
        placeholder="First name"
        onChange={(e) => setFirstNameLocal(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
        value={firstNameLocal}
      />

      <TextInput
        ref={lastNameInput}
        placeholder="Last name"
        onChange={(e) => setLastNameLocal(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
        value={lastNameLocal}
      />

      <Button onClick={() => setName()}>
        NEXT
      </Button>
    </FormPanel>
  );
};

export default UserDataInput;
