import React from 'react';
import styled from 'styled-components';

const LinkStyled = styled.a`
  color: white;
  text-decoration: underline;
  &:hover {
    color: #337ab7;
    text-decoration: underline;
  }
`;

interface Props {
  children: React.ReactNode,
  [key: string]: any,         // dynamic keys and values
}

const Link = ({ children, ...props }: Props) => <LinkStyled {...props}>{children}</LinkStyled>;

export default Link;
