import React from 'react';
import { Navigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {LoginInfo} from '@collabra/cway-frontend-common/types/__generated__/graphql';

interface Props {
  queryLoading: boolean,
  queryError?: ApolloError,
  loginInfo: LoginInfo,
}

const RedirectToUserDataInputIfNeeded = ({ queryError, queryLoading, loginInfo }: Props) => {
  if (!queryLoading && !queryError && loginInfo && loginInfo.shouldProvideName && loginInfo.user.acceptedTerms && loginInfo.currentOrganisation) {
    console.log(`URL: ${window.location.href}, redirect to user data input`);
    return <Navigate to="/userDataInput" />;
  }
  console.log(`URL: ${window.location.href}, redirect to user data input SKIPPED`);
  return null;
};

export default RedirectToUserDataInputIfNeeded;
