// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {TimesIcon} from '@collabra/cway-frontend-common/icons';
import {ReactComponent as CwayLogoWhiteIcon} from '../../img/icons/cway-logo-white.svg';
import chatButtonBackground from '../../img/icons/intercom-icon-background.svg';
import DynamicIcon from '../DynamicIcon';
import styled from 'styled-components';

const ChatButton = styled.button`
  position: absolute;
  right: 30px;
  bottom: 30px;
  margin-left: 10px;
    
  width: 60px;
  height: 60px;
  border: none;
  background: transparent url(${chatButtonBackground}) 100% 100% no-repeat;
  padding-top: 1px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;
const ChatIcon = styled(DynamicIcon)`
  width: 70%;
  height: 70%;
  color: white;
`;
const ChatHolder = styled.div<{ $isHidden: boolean }>`
  z-index: 1000000;
  position: fixed;
  right: 30px;
  bottom: 100px;
  background-color: white;
  visibility: ${({$isHidden}) => $isHidden ? 'hidden' : 'visible'};
  border-radius: 8px;
  box-shadow: 0 0 13px rgba(30, 30, 30, 0.3);
  overflow: hidden;
`;
const Chat = styled.div`
  height: 500px;
  width: 100%;
  #hubspot-conversations-inline-parent {
    #hubspot-conversations-inline-iframe {
      height: 500px;
    }
  }
`;

const ChatWidget = () => {
    // console.group('%cChatWidget()', 'color: red');

    const [isReady, setIsReady] = useState(false);
    // console.log('isReady: ', isReady);
    const [isHidden, setIsHidden] = useState(true);
    const ChatHolderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // console.group('%cChatWidget component mount', 'color: red');
        // console.log('Load Hubspot script');
        const script = document.createElement('script');

        const loadChatScriptByURL = () => {
            // console.group('%cloadChatScriptByURL()', 'color: red');
            const url = `//js-eu1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
            // console.log('url: ', url);
            script.type = 'text/javascript';
            script.src = url;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            // console.groupEnd();
        };

        window.hsConversationsOnReady = [
            () => {
                // console.group('%c>>>>>>>>>> window.hsConversationsOnReady()', 'color: red');
                setIsReady(true);
                window.HubSpotConversations.on('unreadConversationCountChanged', payload => {
                    // console.group('unreadConversationCountChanged()');
                    setIsHidden(false);
                    // console.log('payload: ', payload);
                    // console.groupEnd();
                });
                // console.groupEnd();
            },
        ];
        window.hsConversationsSettings = {
            inlineEmbedSelector: '#hubspot-chat',
        };

        loadChatScriptByURL();

        return () => {
            // console.group('%cChatWidget unmount', 'color: red');
            window.HubSpotConversations.widget.remove();
            document.body.removeChild(script);
            // console.groupEnd();
        };

        // console.groupEnd();
    }, []);

    // console.groupEnd();

    return (
        <>
            <ChatButton
                disabled={!isReady}
                onClick={() => {
                    setIsHidden((prevState) => !prevState);
                    window.HubSpotConversations.widget.open();
                }}
                id="chatLauncher"
            >
                <ChatIcon tag={isHidden ? CwayLogoWhiteIcon : TimesIcon}/>
            </ChatButton>

            {createPortal(
                <ChatHolder ref={ChatHolderRef} $isHidden={isHidden} id="hubspot-chat-holder">
                    <Chat id="hubspot-chat"/>
                </ChatHolder>,
                document.body,
            )}
        </>
    );
};

export default ChatWidget;
