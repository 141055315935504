// @ts-nocheck

// Packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Child components
import ErrorDialog from '../ErrorDialog';

class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    console.group('%cErrorBoundary.getDerivedStateFromError()', 'color: red');
    console.log('error: ', error);
    console.groupEnd();

    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.group('%cErrorBoundary.componentDidCatch()', 'color: red');
    console.log('error: ', error);
    console.log('errorInfo: ', errorInfo);

    this.setState({ errorInfo });

    console.groupEnd();
  }

  render() {
    const { error, errorInfo, hasError } = this.state;
    const { children } = this.props;

    // in case of error - render fallback UI
    if (hasError) {
      console.group('%cErrorBoundary error', 'color: red');
      console.log('error: ', error);
      console.log('errorInfo: ', errorInfo);
      console.log('rendering ErrorDialog: ', ErrorDialog);
      console.groupEnd();

      return <ErrorDialog componentName="ErrorBoundary" />;
    }

    // When there's not an error, render children untouched
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
