/**
 * Mapping of the name of an interface or union type (the supertype)
 * to the types that implement or belong to it (the subtypes)
 * (https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces)
 *
 * All unions of GraphQL schema should be represented here (keys are unions names, array values are unions values).
 *
 * Example: "union Assignment = Approver | Receiver | Supplier" -> "Assignment: ['Approver', 'Receiver', 'Supplier']"
 */

const possibleTypes = {
  Assignment: ['Approver', 'Receiver', 'Supplier'],
  BroadcastFileDescriptor: ['BroadcastFile', 'BroadcastFolder'],
  CommentMarker: [
    'CommentCircleMarker', 'CommentPinMarker', 'CommentRectangleMarker', 'CommentLineMarker',
    'MeasurementCommentLineMarker', 'MeasurementCommentRectangleMarker',
  ],
  DownloadFileDescriptor: ['DownloadFile', 'DownloadFolder'],
  Marker: [
    'CircleMarker', 'PinMarker', 'RectangleMarker', 'LineMarker',
    'MeasurementLineMarker', 'MeasurementRectangleMarker',
  ],
  RevisionEvent: ['Correction', 'RevisionSystemLog'],
};

export default possibleTypes;
