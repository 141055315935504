import React from 'react';
import { Navigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {LoginInfo} from '@collabra/cway-frontend-common/types/__generated__/graphql';

interface Props {
  queryLoading: boolean,
  queryError?: ApolloError,
  loginInfo: LoginInfo,
}

const RedirectToLoginPageIfNotLoggedIn = ({ queryError, queryLoading, loginInfo }: Props) => {
  if (!queryLoading && (queryError || !loginInfo)) {
    console.log(`URL: ${window.location.href}, redirect to login form`);
    return <Navigate to="/login" />;
  }
  console.log(`URL: ${window.location.href}, redirect to login form SKIPPED`);
  return null;
};

export default RedirectToLoginPageIfNotLoggedIn;
