import React, { useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Api from '../Api';
import styled from 'styled-components';
import DynamicIcon from './DynamicIcon';
import Modal from './Modal';
import LoadingIndicator from './LoadingIndicator';
import {Button} from './ButtonStyles';
import Socials from './Socials';
import { getExtensionFromFileName, getFileTypeIcon } from '@collabra/cway-frontend-common/utils';
import { BackendAnonymousShare } from '../types/backend';
import { InfoCircleIconO, CwayLogoTextRightIcon } from './Icons';

const Description = styled.div`
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  width: 400px;
  color: white;
`;
const DescriptionLogo = styled(CwayLogoTextRightIcon)`
  margin-left: -20px;
  width: 180px;
`;
const DescriptionHeader = styled.p`
  margin: 40px 0;
  font-size: 44px;
  font-weight: bold;
  line-height: 1.2;
`;
const Content = styled.div`
  border-bottom: 2px solid #DDDDDD;
  padding-bottom: 10px;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;
const Text = styled.p``;
const FileContents = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: white;
  padding: 5px 10px;
  position: relative;     // for abs-positioned 'before:'
  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20px;
    width: 0;
    border-style: solid;
    border-width: 20px 10px;
    border-color: transparent;
    border-bottom-color: white;
  }
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;
// Need to have an additional container because fileContents have overflowing :before
const ScrollBox = styled.div`
  overflow-y: auto;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    margin-top: 5px;
  }
`;
const ItemIcon = styled(DynamicIcon)`
  margin-right: 10px;
  font-size: 20px;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
`;
const InfoIcon = styled(InfoCircleIconO)`
  margin-right: 10px;
  font-size: 16px;
`;
const ButtonStyled = styled(Button)`
  border-radius: 14px;
  background-color: #A6B3C3;
  padding: 8px;
  color: white;
`;

// const items = [
//   '64531 5732210 KENNEL Mastiff Mocha 2dl.pdf',
//   '64531 5732210 KENNEL Mastiff Mocha 2dl.ai',
//   'Carrots.psd',
//   'Onions.psd',
//   'Dog.psd',
//   'Onions.psd',
//   '64531 5732210 KENNEL Mastiff Mocha 2dl.pdf',
//   '64531 5732210 KENNEL Mastiff Mocha 2dl.ai',
//   'Carrots.psd',
//   'Onions.psd',
//   'Dog.psd',
//   'Onions.psd',
// ];

const Download = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'Download | Cway';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway download page');

  // ---------- Get data by ID from URL on component mount --------------------

  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState<string | null>(null);
  const [data, setData] = useState<BackendAnonymousShare | null>(null);

  const { shareId } = useParams<{ shareId?: string }>();

  useEffect(() => {
    Api.getAnonymousDownload(
      (responseData) => {
        setDataLoading(false);
        setData(responseData);
      },
      (message: string) => {
        setDataError(message);
      },
      shareId,
    );
  }, []);

  // ---------- Show only loading indicator while data is being loaded --------------------

  if (dataLoading) {
    return <LoadingIndicator fullscreen />;
  }

  // ---------- Set dialog title and content depending on whether data loaded successfully or not --------------------

  let dialogTitle = '';
  let dialogContent: ReactNode = '';

  if (dataError) {
    dialogTitle = 'Error';
    dialogContent = dataError;
  } else {
    if (data?.name) {
      dialogTitle = data.name;
    }

    if (data?.items) {
      dialogContent = (
        <Content>
          <Text>Created by: name.name@company.com</Text>
          <Text>Created: yyyy-mm-dd</Text>
          <br />

          <Text>
            Message: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Mauris id justo egestas, convallis ex eu, tempor quam.
            Class aptent taciti socioscu ad litora torquent per conubia nostra.
          </Text>
          <br />
          <br />

          <Text>Contents of downloaded file</Text>
          <FileContents>
            <ScrollBox>
              {data.items.map((item) => {
                const Icon = getFileTypeIcon(getExtensionFromFileName(item.name) ?? '');
                return (
                  <Item key={uuidv4()}>
                    <ItemIcon tag={Icon}/>
                    <span>{item.name}</span>
                  </Item>
                );
              })}
            </ScrollBox>
          </FileContents>

          <Info>
            <InfoIcon/>
            <Text>The selected media will be stored on your computer in a zip file <b>{data.name}</b></Text>
          </Info>
        </Content>
      );
    }
  }

  // ------------------------------------------------------------------------------------------

  return (
    <div>
      <Modal
        title={dialogTitle}
        content={dialogContent}
        buttons={[
          <ButtonStyled onClick={() => {}}>
            Download
          </ButtonStyled>,
        ]}
      />

      <Description>
        <DescriptionLogo/>
        <DescriptionHeader>Media<br />management<br />without all the<br />management</DescriptionHeader>
        <p>The online media management<br />hub that makes it easy to plan,<br />design, manage, store and<br />share media at any time.\</p>
      </Description>

      <Socials />
    </div>
  );
};

export default Download;
