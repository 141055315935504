import React from 'react';
import { Navigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {LoginInfo} from '@collabra/cway-frontend-common/types/__generated__/graphql';

interface Props {
  queryLoading: boolean,
  queryError?: ApolloError,
  loginInfo: LoginInfo,
}

const RedirectToOrganisationIfNeeded = ({ queryError, queryLoading, loginInfo }: Props) => {
  if (!queryLoading && !queryError && loginInfo && !loginInfo.currentOrganisation) {
    console.log(`URL: ${window.location.href}, redirect to organisation`);
    return <Navigate to="/organisation" />;
  }
  console.log(`URL: ${window.location.href}, redirect to organisation SKIPPED`);
  return null;
};

export default RedirectToOrganisationIfNeeded;
