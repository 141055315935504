// @ts-nocheck
import React, { useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { useQuery, gql } from '@apollo/client';
import {Button} from '../ButtonStyles';
import Login from '../Login';
import Modal from '../Modal';
import LoadingIndicator from '../LoadingIndicator';
import { getEnvironment, getCwayUrl, openInNewTab } from '@collabra/cway-frontend-common/utils';
import { getAuthToken } from '@collabra/cway-frontend-common/utils/auth';
import { colorsEnum, appsEnum, environmentsEnum } from '@collabra/cway-frontend-common/constants';

const LOGIN_INFO = gql`
    query LoginInfo {
        loginInfo {
            user {
                username
                firstName
                lastName
            }
            currentOrganisation {
                id
                name
            }
        }
    }
`;

interface Props {
  userMessage?: string,
  showButtons?: boolean,
  componentName?: string,
}

const ErrorDialog = ({ userMessage = null, showButtons = true, componentName = '' }: Props) => {
  console.group('ErrorDialog()');

  // ---------- Get loginInfo data and JWT token --------------------

  const { data: loginInfoData, loading: loginInfoLoading, error: loginInfoError } = useQuery(LOGIN_INFO);
  console.log('loginInfoLoading: ', loginInfoLoading);
  if (loginInfoLoading) {
    console.groupEnd();
    return <LoadingIndicator fullscreen />;
  }

  console.log('%ccomponentName: ', 'color: green; font-weight: bold', componentName);
  console.log('%cuserMessage: ', 'color: red; font-weight: bold', userMessage);
  console.log('%cshowButtons: ', 'color: red; font-weight: bold', showButtons);
  console.log('loginInfoError?.networkError?.message: ', loginInfoError?.networkError?.message);
  console.log('loginInfoError?.networkError?.statusCode: ', loginInfoError?.networkError?.statusCode);

  const errorStatusCode = loginInfoError?.networkError?.statusCode;
  console.log('errorStatusCode: ', errorStatusCode);
  const userUnauthorized = (errorStatusCode === 401) || (errorStatusCode === 403);
  console.log('userUnauthorized: ', userUnauthorized);
  const serverDown = errorStatusCode && (errorStatusCode >= 500) && (errorStatusCode <= 511);
  console.log('serverDown: ', serverDown);

  const jwtToken = getAuthToken();
  console.log('jwtToken last 10 symbols: ', jwtToken?.slice(-10));

  // ---------- Reload page function --------------------

  const reloadPage = () => window.location.reload();

  // ---------- Server error --------------------

  if (serverDown) {
    console.log('----- Server fail: show message');
    console.groupEnd();
    return (
      <Modal
        title="Cway server is not responding"
        content="Please try again later"
        buttons={[
          <Button onClick={reloadPage}>Reload page</Button>,
        ]}
      />
    );
  }

  // ---------- JWT token has just expired but page was not reloaded yet --------------------
  // ---------- (token exists but no longer valid, orgId also exists and there is 401 error (user is unauthorized) --------------------

  const orgId = loginInfoData?.loginInfo?.currentOrganisation?.id;
  console.log('orgId: ', orgId);
  // Token lifetime is counted in seconds so convert it to milliseconds
  const tokenExistsButExpired = jwtToken ? (jwtDecode(jwtToken).exp * 1000 < Date.now()) : false;
  console.log('tokenExistsButExpired: ', tokenExistsButExpired);

  console.log('process.env.REACT_APP_CWAY_APPLICATION: ', process.env.REACT_APP_CWAY_APPLICATION);
  const separateLogin = process.env.REACT_APP_CWAY_APPLICATION === appsEnum.broadcast;
  console.log('separateLogin: ', separateLogin);

  if (((tokenExistsButExpired && orgId) || separateLogin) && userUnauthorized) {
    if (tokenExistsButExpired && orgId) console.log('----- Auth token have just expired: show login form');
    else console.log('----- App login is separate from main app: show login form');
    console.groupEnd();
    // Display simplified login dialog where user can enter credentials without company.
    return <Login orgId={orgId} />;
  }

  // ---------- Not login app: JWT token doesn't exist or loginInfo gives 401 error (Unauthorized) or no organisation selected --------------------

  // This means that user is logged out intentionally or page has been reloaded after logout on token expire.
  // Also last login can be in admin application which doesn't require entering organisation.
  // We can't reliably simplify relogin so user is redirected to main login page.
  if ((process.env.REACT_APP_CWAY_APPLICATION !== appsEnum.login) && (!jwtToken || userUnauthorized || !orgId)) {
    console.log('----- Unauthorized: show message');
    const redirectToLoginPage = () => openInNewTab(getCwayUrl({ env: getEnvironment(), app: appsEnum.login }));
    console.groupEnd();
    return (
      <Modal
        title="Authentication error"
        content="You have been logged out and need to log in"
        buttons={[
          <Button onClick={redirectToLoginPage}>Go to login page</Button>,
        ]}
      />
    );
  }

  // ---------- JWT token is valid --------------------

  let reportButton = null;

  if (getEnvironment() !== environmentsEnum.development) {
    console.log('%c----- Auth token is valid: show message', 'color: red');

    const firstName = loginInfoData?.loginInfo?.user?.firstName;
    const lastName = loginInfoData?.loginInfo?.user?.lastName;
    const username = loginInfoData?.loginInfo?.user?.username;
    const organisation = loginInfoData?.loginInfo?.currentOrganisation?.name;
    // Get file/artwork IDs from URL
    const urlParams = new URLSearchParams(window.location.search);
    let urlParameter = null;
    if (urlParams.get('file1Id') && urlParams.get('file2Id')) {
      urlParameter = { file1Id: urlParams.get('file1Id'), file2Id: urlParams.get('file2Id') };
    } else if (urlParams.get('file1Id')) {
      urlParameter = { file1Id: urlParams.get('file1Id') };
    } else if (urlParams.get('artworkId')) {
      urlParameter = { artworkId: urlParams.get('artworkId') };
    } else if (urlParams.get('mediaCenterArtworkId')) {
      urlParameter = { mediaCenterArtworkId: urlParams.get('mediaCenterArtworkId') };
    }
  }

  console.groupEnd();

  // --------------------------------------------------------------------------------

  return (
    <Modal
      title="Application error"
      content={userMessage || 'Something went wrong'}
      buttons={showButtons ? [
        <Button onClick={reloadPage}>Reload page</Button>,
        reportButton,
      ] : []}
    />
  );
};

export default ErrorDialog;
