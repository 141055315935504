import React from 'react';
import styled from 'styled-components';
import {CwayLogoTextDownIcon} from '../Icons';

const LogoWrapper = styled.h1``;
const LogoIcon = styled(CwayLogoTextDownIcon)`
  width: 150px;
  height: 150px;
`;

const Logo = () => (
  <LogoWrapper>
    <LogoIcon/>
  </LogoWrapper>
);

export default Logo;
